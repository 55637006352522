import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Link, navigate } from 'gatsby';
export const content = [{
  title: 'Zapoj sa do programu',
  text: 'Mnohí z nás (sa) radi učia. Máš skúsenosti s prednášaním alebo s ním chceš začať?',
  url: '/zapoj-sa/program'
}, {
  title: 'Chceš vytvárať hodnotný obsah?',
  text: 'Text je historicky overená forma zdieľania myšlienok. Ponúkame priestor, kde sa môžeš podeliť o tie svoje.',
  url: '/zapoj-sa/obsah'
}, {
  title: 'Ovládaš cudzie jazyky?',
  text: 'Ak ovládaš cudzie jazyky, pomôž s prekladom a prines tak zaujímavé myšlienky aj pre tých, ktorí sa v zahraničných zdrojoch neorientujú.',
  url: '/zapoj-sa/cudzie-jazyky'
}, {
  title: 'Pomôž s adopciou kryptomien',
  text: 'Zaujímaš sa o kryptomeny a myslíš si, že schopnosť narábania s nimi je superschopnosť? Pomôž ju šíriť ďalej.',
  url: '/zapoj-sa/adopcia'
}, {
  title: 'Príď s vlastným nápadom!',
  text: 'Určite sa nájde mnoho vecí, o ktorých sme doteraz nepremýšľali. Máš nápad, ktorý spĺňa naše hodnoty a rád by si ho realizoval?',
  url: '/zapoj-sa/vlastny-napad'
}, {
  title: 'Nevieš za rozhodnúť? Podpor nás finančne',
  text: 'Sme nezisková organizácia, nezávislá na štátnych peniazoch. Pri našej snahe sú dôležité (aj tvoje) dobrovoľné príspevky.',
  url: '/zapoj-sa/podpora'
}];
const layoutProps = {
  content
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>



      <MDXTag name="p" components={components}>{`Ako vždy, práce a aj nápadov je stále viac než je dostupných ľudí a času. Radi prijmeme každú pomoc, aby sme spolu dosiahli viac. Ak chceš tiež priložiť ruku k spoločnému dielu, nižšie nájdeš aktivity, do ktorých sa môžeš ihneď zapojiť. Tešíme sa na teba!`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`V Paralelnej Polis Košice chceme vytvoriť prostredie zamerané na vzdelávanie, objavovanie a tvorenie lepších systémov pre naše okolie. Chceme žiť v slobodnejšom a otvorenejšom svete. Hľadáme spôsoby a technológie (Bitcoin, blockchain, reputačné systémy a decentralizované technológie), ktoré nám otvárajú možnosti, zjednodušujú bežné procesy a odstraňujú bariéry. `}<MDXTag name="a" components={components} parentName="p" props={{
            "href": "/o-paralelnej-polis"
          }}>{`Prečítaj si viac o tom, kto sme`}</MDXTag>{`.`}</MDXTag>
      </MDXTag>
      <div className="flex flex-wrap -mx-2 mb-8">
  {content.map((item, i) => <div className="sm:w-1/2 p-2" key={item.url}>
      <div className="group bg-grey-lighter hover:bg-grey-light focus:bg-grey-light cursor-pointer p-4" onClick={() => navigate(item.url)} role="link" tabIndex="0" onKeyUp={e => e.key === 'Enter' ? navigate(item.url) : undefined}>
        <h2>
          <Link to={item.url}>
            <span className="text-grey sm:text-4xl font-bold -mt-3 sm:float-right">
              {i + 1}.
            </span>{' '}
            {item.title}
          </Link>
        </h2>
        <p className="text-sm m-0">{item.text}</p>
      </div>
    </div>)}
      </div>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      